import Axios, { AxiosError } from 'axios';
import { parseCookies } from 'nookies';

const api = Axios.create({
    baseURL: '/api/',
});

const WHITELISTED_PATHS_FOR_AUTH = ['/', '/login', '/support/tm'];

const IS_DEV = process.env.NODE_ENV !== 'production';
const IS_BROWSER = typeof window !== 'undefined';

api.interceptors.request.use(
    async (config) => {
        const cookies = parseCookies();
        const updatedReqConfig = {
            ...config,
            headers: {
                ...config.headers,
                authorization: cookies.token,
            },
        };
        return updatedReqConfig;
    },
    (error) => {
        if (IS_DEV && IS_BROWSER) {
            // eslint-disable-next-line no-console
            console.error('✉️ ', error);
        }

        return Promise.reject(error);
    },
);

api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (
            IS_BROWSER &&
            !WHITELISTED_PATHS_FOR_AUTH.includes(window.location.pathname) &&
            error.response?.status === 403
        ) {
            window.location.href = '/login';
        }
        return Promise.reject(error);
    },
);

export default api;
