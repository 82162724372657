/* eslint-disable react/jsx-props-no-spreading */
import 'antd/dist/antd.css';
import '../styles/variables.css';
import '../styles/global.css';
import { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthUserProvider } from '../contexts/authContext';

const QUERY_CLIENT = new QueryClient();

const App = ({ Component, pageProps }: AppProps) => (
    <QueryClientProvider client={QUERY_CLIENT}>
        <AuthUserProvider>
            <Component {...pageProps} />
        </AuthUserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);

export default App;
