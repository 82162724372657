// import { WarningTwoTone } from '@ant-design/icons';
// import { notification } from 'antd';
import { createContext, useContext, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { captureEvent, Severity } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { TUser } from '../types_data';
import api from '../utils/axios';

type TAuthContext = {
    authUser: TUser | null;
    isLoading: boolean;
    signIn: (_: string, __: string) => void;
    signOut: () => void;
};

const AuthUserContext = createContext<TAuthContext>({
    authUser: null,
    isLoading: false,
    signIn: async (_: string, __: string) => {},
    signOut: async () => {},
});

export const AuthUserProvider = ({ children }: { children: any }) => {
    const [authUser, setAuthUser] = useState<TUser | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalDismissed, setIsModalDismissed] = useState(false);
    const [manuallyLoggedIn, setIsManuallyLoggedIn] = useState(false);

    const router = useRouter();
    const page = router.pathname;

    const dontYouKnowWhoIAm = async () => {
        try {
            const user = (await api.post<TUser>('/auth/whoami')).data;
            if (user) {
                setAuthUser(user);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.info('Not logged in');
        }
    };

    useEffect(() => {
        dontYouKnowWhoIAm();
    }, []);

    const doRedirect = (user: TUser) => {
        if (user) {
            switch (user.type) {
                case 'superadmin':
                case 'admin':
                    router.push('/admin');
                    break;
                case 'surveyor':
                    router.push('/surveyor');
                    break;
                default:
                    router.push('/');
            }
        } else {
            captureEvent({
                message: 'Tried to redirect without user',
                level: Severity.Error,
            });
        }
    };

    const dismissModal = () => {
        setIsModalDismissed(true);
    };

    const signIn = async (email: string, password: string) => {
        setIsManuallyLoggedIn(true);
        try {
            setIsLoading(true);
            const res = await api.post<TUser>('/auth/login', { email, password });
            setAuthUser(res.data);

            doRedirect(res.data);
        } catch (error) {
            captureEvent({
                message: 'Login failed',
                level: Severity.Error,
            });
        }
        setIsLoading(false);
    };

    const signOut = async () => {
        setIsLoading(true);
        await api.post<TUser>('/auth/logout');
        setIsLoading(false);
        router.push('/');
    };

    const showModal =
        // Only show on login page
        router.isReady &&
        page === '/login' &&
        // and disable modal if user entered email/pass
        !manuallyLoggedIn &&
        // and if user exists in state
        !!authUser &&
        // and if modal isn't dismissed
        !isModalDismissed;

    return (
        <AuthUserContext.Provider
            value={{
                authUser,
                isLoading,
                signIn,
                signOut,
            }}>
            <Modal
                visible={showModal}
                onOk={() => {
                    if (authUser) doRedirect(authUser);
                    dismissModal();
                }}
                cancelText="Login as another account"
                onCancel={dismissModal}
                okText="Continue to dashboard"
                title="Note">
                <p>You are already logged in as {authUser?.email}</p>
            </Modal>
            {children}
        </AuthUserContext.Provider>
    );
};

// custom hook to use the authUserContext and access authUser and loading
export const useAuth = () => useContext(AuthUserContext);
